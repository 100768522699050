@import './OrderHistory.scss';

#account-template{
    padding: 30px 0;
    .account-greeting-wrapper{
        padding: 20px 0;
        h3.greetings{
            font-family: Moret;
            font-size: 3rem;
            font-weight: 600;
        }
    }
    .account-content-wrapper{
        padding: 20px 0;
        .account-left-menu{
            .content-sticky{
                margin-top: 120px;
            }
            a{
                border-radius: 5px;
                color: #8E8E8E;
                display: inline-block;
                font-weight: 600;
                margin-bottom: 15px;
                padding: 10px;
                width: 220px;
                transition: background-color 300ms ease-out;
                -moz-transition: background-color 300ms ease-out;
                -o-transition: background-color 300ms ease-out;
                transition: background-color 300ms ease-out;
                span.account-menu-icon{
                    float: left;
                    margin: 0 10px;
                    width: 25px;
                }
                &.active,
                &:hover{
                    background-color: #000;
                    color: #fff;
                }
            }
        }
        .account-content{
            background: #FFFFFF;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #2920181A;
            border-radius: 4px;
            padding: 50px;
            h3.title{
                font-family: Moret;
                font-size: 3rem;
                font-weight: 600;
            }
        }
    }
}
h5.form-title{
    font-weight: 600;
    margin-bottom: 30px;
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;
}
form.account-form{
    max-width: 480px;
    .form-group{
        margin-bottom: 0;
        input[type=text],
        input[type=password]{
            border-radius: 0;
            border: 2px solid #272727;
            padding: 30px 20px;
            &:focus{
                box-shadow: none;
                outline: none;
            }
        }
    }
    span.warning-input{
        color: #EA5253;
        display: inline-block;
        margin-bottom: 20px;
        font-size: 14px;
        padding-top: 5px;
    }
}

.section-account{
    .address-list{
        margin-bottom: 35px;
        .address-wrap{
            background-color: #FFFCF9;
            border: 2px solid #fff;
            clear: both;
            display: inline-block;
            padding: 15px;
            width: 100%;
            &.primary-address{
                border: 2px solid #000;
                position: relative;
                &::after{
                    background-color: #000;
                    color: #fff;
                    content: 'primary address';
                    clear: both;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0 10px;
                    position: absolute;
                    text-transform: uppercase;
                    right: 0;
                    top: 0;
                }
            }
            span{
                clear: both;
                display: inline-block;
                width: 100%;
                &.bolder{
                    font-weight: 600;
                }
                &.remarks{
                    margin-top: 5px;
                    font-style: italic;
                }
            }
        }
        .address-action{
            display: inline-block;
            padding: 0 20px;
            width: 100%;
            button, a{
                color: #7B7B7B;
                display: inline-block;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 5px;
                width: 100%;
                span.icon{
                    display: inline-block;
                    width: 25px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &:hover{
                    color: #272727;
                }
            }
        }
    }
}

.checkbox-default-info{
    background-color: #FFF7ED;
    padding: 10px 20px 0 20px;
}

@media only screen and (max-width: 990px) {
    #account-template .account-content-wrapper{
        .account-left-menu a{
            width: 100%;
        }
        .account-content{
            padding: 15px;
        }
        h5.form-title{
            display: inline-block;
            span{
                clear: both;
            }
            a.button-link{
                float:left !important;
                padding: 5px 0 0 0;
            }
        }
    }

    .section-account .address-list{
        .address-wrap.primary-address{
            padding: 30px 20px;
        }
        .address-action{
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
        }
    }
}