.form-group{
    margin-bottom: 30px;
    input[type=text],
    input[type=password],
    textarea,
    select{
        border: 2px solid #272727;
        border-radius: 0;
        font-size: 20px;
        height: 72px;
        outline: none;
        padding: 20px;
        &:focus{
            box-shadow: none;
            outline: none;
        }
        &.has-error{
            border: 2px solid red;
        }
    }
    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: 20px;
        &:focus{
            outline: none;
            border: 2px solid #272727;
        }
    }
    textarea{
        height: 140px;
    }
    input[readonly]{
        background-color: #F7F1EC;
        border: 2px solid #C3C0BE;
    }
    .warning-input{
        display: none;
    }
    button.submit-btn{
        border-radius: 0;
        background-color: #000;
        color: #fff;
        font-weight: 550;
        &:disabled{
            background-color: #808080;
        }
    }
}

.form-autocomplete-group{
    .autocomplete-select__control{
        border: 2px solid #272727;
        border-radius: 0;
        padding: 15px 20px;
        box-shadow: none;
        &:hover{
            border: 2px solid #272727;
            box-shadow: none;
        }
    }
    .select-autocomplete-wrapper{
        font-size: 20px;
        &:focus{
            outline: none;
        }
        .autocomplete-select__value-container{
            padding: 0;
        }
    }
}


.default-form{
    .form-group.checkbox-wrapper{
        margin-bottom: 20px;
    }
}