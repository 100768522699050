.checkout-section{
    display: flex;
    .cart-info{
        width: 50%;
        background-color: #FFFCF9;
        display: flex;
        justify-content: flex-start;
        padding-top: 20px;
        h3{
            border-bottom: 1px solid #AFAFAF;
            padding-bottom: 20px;
            a{
                display: none;
                font-size: 20px;
                margin-right: 5px;
                padding: 5px 10px;
            }
            span{
                font-family: Moret;
                font-size: 2.8rem;
                font-weight: 600;
            }
        }
        .item-content{
            color: #272727;
            display: block;
            //height: 470px;
            margin-top: 20px;
            position: relative;
            .item{
                border-top: 1px solid #AFAFAF;
                display: inline-block;
                padding: 20px 0;
                position: relative;
                width: 100%;
                &:first-child{
                    border-top: none;
                }
                .product-info{
                    display: flex;
                    .product-image-wrapper{
                        display: flex;
                        img{
                            display: block;
                            width: 162px;
                            height: 162px;
                        }
                    }
                    .product-note{
                        padding: 0 10px;
                        width: 100%;
                        span{
                            clear: both;
                            display: inline-block;
                            width: 100%;
                            &.title{
                                font-weight: 600;
                                font-size: 1.2em;
                            }
                            &.attr{
                                color: #808080;
                                font-size: 1em;
                            }
                            &.highlight{
                                color: #000;
                                font-size: 1em;
                                font-weight: 600;
                            }
                            &.mobile-price{
                                display: none;
                            }
                        }
                        .product-action-link{
                            clear: both;
                            display: inline-block;
                            margin-top: 5px;
                            a, button{
                                border-left: 1px solid #808080;
                                color: #005F85;
                                text-transform: uppercase;
                                margin-left: 10px;
                                padding-left: 10px;
                                &:first-child{
                                    border-left: none;
                                    margin-left: 0;
                                    padding-left: 0;
                                }
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                .qty-control{
                    display: inline-block;
                    font-size: 20px;
                    span{
                        color: #272727;
                        float: left;
                        text-align: center;
                    }
                }
                button.delete{
                    float: right;
                    margin-right: 20px;
                    font-size: 22px;
                    span{
                        display: none;
                    }
                }
                .delete-confirmation-block{
                    background-color:rgba(255, 255, 255, 0.9);
                    clear: both;
                    height: 100%;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    span{
                        font-size: 22px;
                    }
                    .wrap-confirm-buttons{
                        padding-top: 10px;
                        button{
                            border-right: 1px solid #000;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 0 20px;
                            &.remove{
                                color: #ee3030;
                            }
                            &:last-child{
                                border-right: none;
                            }
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .personalized-info{
            border: 1px solid #ebe8e8;
            border-radius: 5px;
            display: block;
            padding: 10px 20px;
            margin-bottom: 15px;
            position: relative;
            width: 100%;
            .title{
                display: inline-flex;
                font-weight: 600;
                font-size: 1rem;
                justify-content: space-between;
                padding-bottom: 10px;
                width: 100%;
            }
            .personalize-list{
                display: block;
                width: 100%;
                .item-p{
                    display: inline-block;
                    width: 100%;
                    span{
                        display: inline-block;
                        width: 33%;
                    }
                }
            }
        }
        .price-summary{
            border-top: 1px solid #AFAFAF;
            padding: 20px 0;
            .row-info{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                span.label{
                    color: #767676;
                    text-transform: uppercase;
                }
                span.value{
                    &.discount{
                        color: #005F85;
                    }
                }
                &.grand-total{
                    border-top: 1px solid #AFAFAF;
                    padding-top: 20px;
                    span{
                        color: #000;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .user-info{
        width: 50%;
        background-color: #ffffff;
        padding-top: 17px;
        .navbar-checkout{
            display: flex;
            padding: 0 50px;
            width: 100%;
            z-index: 1;
            .brand-area{
                display: flex;
                a.link-brand{
                    width: 170px;
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .info-content-user{
            display: flex;
            justify-content: flex-end;
            .checkout-shipping{
                padding: 0 35px 35px 35px;
                width: 730px;
                &.checkout-guest-main{
                    height: auto;
                    position: relative;
                }
                .auth-content, 
                .guest-checkout{
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                    max-width: 500px;
                }
                h3{
                    font-family: RealistWide;
                    font-size: 25px;
                }
                .breadcrumb-checkout{
                    text-transform: uppercase;
                    div{
                        color: #C1C1C1;
                        display: inline;
                        font-size: 14px;
                        padding-right: 10px;
                        &.visited{
                            color: #272727;
                        }
                        &.current{
                            color: #000;
                            font-weight: 600;
                        }
                        &.passed{
                            color: #272727;
                        }
                        span{
                            padding: 0 3px 0 14px;
                        }
                    }
                }
            }
        }
    }
    .info-content{
        padding: 20px 35px;
        width: 730px;
    }
}


// checkout shipping
.info-card{
    margin-top: 30px;
    h3{
        font-family: Moret;
        font-size: 3rem;
        font-weight: bold;
    }
    .info-box{
        border: 3px solid #272727;
        font-size: 18px;
        margin-top: 25px;
        padding: 20px;
        max-width: 75%;
        span{
            display: inline-block;
            line-height: 25px;
            width: 100%;
            &.highlight{
                font-weight: 600;
            }
        }
        button{
            font-size: 16px;
            margin-top: 20px;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .payment-button{
        max-width: 75%;
    }
}

// guest checkout 
.guest-checkout-card{
    .info-card{
        h3{
            margin-bottom: 30px;
        }
        .info-box{
            max-width: 100%;
            width: 100%;
        }
    }
}

.form-guess-checkout{
    max-width: 100% !important;
    .ship-options .service-options .row-service{
        max-width: 100%;
    }
}

.ship-options{
    h5{
        margin-top: 15px;
    }
    .service-options{
        margin-top: 20px;
        .row-service{
            padding: 15px;
            max-width: 75%;
            &.selected{
                background-color: #FFF7ED;
            }
            .container-checkbox{
                margin: 0;
                width: 100%;
                .service-value{
                    display: inline-block;
                    width: 100%;
                    span{
                        float: right;
                        width: auto;
                        &:first-child{
                            float: left;
                        }
                    }
                }
            }
        }
    }
}

// payment
.payment-result{
    padding: 50px 0;
    max-width: 480px;
    margin: 0 auto;
    .icon-wrapper{
        &.success{
            svg{
                color: #008000;
                font-size: 50px;
            }
        }
        &.unfinished{
            svg{
                color: #ffa500;
                font-size: 50px;
            }
        }
        &.error{
            svg{
                color: #ff0000;
                font-size: 50px;
            }
        }
    }
    p{
        font-size: 20px;
    }
    h3{
        font-family: Moret;
        font-size: 3.5rem;
        font-weight: 600;
        padding: 20px 0;
    }
    .button{
        margin-top: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .checkout-section .user-info .navbar-checkout{
        display: none;
    }
    .checkout-section .cart-info .item-content .item .product-info{
        .product-note{
            span.title {
                font-size: 1em;
            }
            span.attr {
                font-size: 0.8em;
            }
            span.highlight {
                color: #808080;
                font-size: 0.8em;
                font-weight: 500;
            }
            span.mobile-price{
                margin-top: 5px;
                display: block;
            }
        }
        .product-image-wrapper img{
            height: 90px;
            width: 90px;
        }
    }
    .checkout-section {
        display: inline-block;
        height: 100%;
        width: 100%;
        .cart-info,
        .user-info{
            display: inline-block;
            width: 100%;
        }
        .cart-info h3 {
            a{
                display: inline;
            }
            span{
                font-size: 2.2rem;
            }
        }
        .info-content{
            width: 100%;
            .price{
                display: none;
            }
        }
    }

    .info-content-user{
        justify-content: flex-start !important;
        overflow: hidden;
        width: 100%;
        .checkout-shipping{
            width: 100%;
            .breadcrumb-checkout{
                text-transform: uppercase;
                div{
                    font-size: 11px !important;
                }
            }
            .info-card{
                h3{
                    font-size: 2rem;
                }
                .info-box{
                    max-width: 90%;
                    font-size: 14px;
                    button{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}