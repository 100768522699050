.dialog-medium{
    border-radius: 0 !important;
    border: 2px solid #000;
    min-width: 680px;
    padding: 20px;
    .responsive-dialog-title > button{
        border: 2px solid #000;
        color: #000;
        padding: 5px;
        right: 20px;
        top: 20px;
        z-index: 10;
    }
}

// form checkout
.form-wrapper-checkout{
    position: relative;
    margin-top: -60px;
    .checkout-section-form{
        h5{
            font-family: Moret;
            font-size: 2rem;
        }
        form.account-form{
            max-width: 100% !important;
            width: 100%;
        }
        .address-list{
            margin-top: 15px;
        }
        .address-wrap{
            background-color: #FFFCF9;
            border: 2px solid #eaeaea;
            clear: both;
            display: inline-block;
            margin-top: 20px;
            padding: 15px;
            width: 100%;
            &.primary-address{
                border: 2px solid #000;
                position: relative;
                &::after{
                    background-color: #000;
                    color: #fff;
                    content: 'primary address';
                    clear: both;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0 10px;
                    position: absolute;
                    text-transform: uppercase;
                    right: 0;
                    top: 0;
                }
            }
            span{
                clear: both;
                display: inline-block;
                width: 100%;
                &.bolder{
                    font-weight: 600;
                }
                &.remarks{
                    margin-top: 5px;
                    font-style: italic;
                }
            }
            .wrap-buttons{
                padding-top: 10px;
                button{
                    padding: 0;
                    &:first-child{
                        margin-right: 15px;
                        border-right: 1px solid #000;
                        padding-right: 15px;
                    }
                }
                .current-info-text{
                    display: inline;
                    text-transform: uppercase;
                }
            }
        }
    }
}

//address checkout
button.add-address-checkout{
    border: 2px dotted #000;
    border-radius: 10px;
    font-weight: 600;
    padding: 20px;
    text-transform: uppercase;
    width: 100%;
}

@media only screen and (max-width: 990px) {
    .dialog-medium{
        border: none;
        min-width: auto;
    }
}