.catalog-wrapper{
    .category-information h2{
        font-family: Moret;
        font-size: 3.5rem;
        font-weight: 600;
    }
    .wrap-product{
        background: none;
        color: inherit;
        padding: 10px 0;
        display: inline-block;
        a{
            background: none;
            display: block;
            padding: 0;
            margin: 0;
            text-align: center;
            width: 100%;
        }
        .wrap-product-image{
            width: 100%;
            position: relative;
            .transition-image{
                opacity: 50%;
                transition:opacity 0.8s
            }
        }
        span{
            color: #000;
            display: block;
            padding: 5px 0;
            text-align: center;
            width: 100%;
            &.title{
                font-weight: 600;
                font-size: 1.5rem;
            }
            &.price{
                font-size: 1.2rem;
            }
        }
    }
}



.color-picker{
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    width: 100%;
    span.title{
        color: #767676;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    button.color-button{
        display: inline-block;
        border: 2px solid #fff;
        border-radius: 30px;
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        padding-bottom: 3px;
        position: relative;
        margin: 0 3px;
        transition: border 300ms;
        -webkit-transition: border 300ms;
        .color{
            border-radius: 22px;
            box-sizing: border-box;
            clear: both;
            width: 22px;
            height: 22px;
            position: absolute;
            right: 2px;
            top: 2px;
        }
        &:hover,
        &.selected{
            border: 2px solid #000;
        }
    }
}


@media only screen and (max-width: 768px) {
    .catalog-wrapper .wrap-product .wrap-product-image{
        min-height: auto;
        width: 100%;
    }
}