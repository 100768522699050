.slide-cart{
    height: 100%;
    .slide-pane__content{
        border-left: 1px solid #000;
        padding: 0;
        overflow-y: visible;
    }
}


.slide-cart-overlay{
    z-index: 10;
}


button.cart-icon{
    background: none;
    border: none;
    display: flex;
    outline: none;
    position: relative;
    .cart-counter{
        font-weight: 550;
        margin-left: -26px;
        margin-top: 9px;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 26px;
        font-size: 1em;
    }
    &:last-child{
        margin-right: 0;
    }
    &:hover{
        opacity: 0.8;
    }
    
}


.product-action-link{
    clear: both;
    display: inline-block;
    a, button{
        border-left: 1px solid #808080;
        color: #005F85;
        font-size: 1rem;
        text-transform: uppercase;
        margin-left: 10px;
        padding-left: 10px;
        &:first-child{
            border-left: none;
            margin-left: 0;
            padding-left: 0;
        }
        &:hover{
            text-decoration: underline;
        }
    }
}

.content-wrapper{
    background: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
    .head{
        display: flex;
        flex-direction: column;
        padding: 15px 30px;
        .title-info{
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
            h2{
                font-family: Moret;
                font-size: 2.75rem;
                font-weight: bold;
            }
            button.cart-icon{
                padding: 0;
                margin-top: 3px;
                &:hover{
                    opacity: 1;
                }
            }
        }
        .cart-header{
            border-bottom: 1px solid #afafaf;
            text-transform: uppercase;
            color: #767676;
            padding: 20px 0;
            width: 100%;
            .title{
                display: inline-block;
                width: 20%;
                &.product{
                    width: 50%;
                }
                &.action{
                    width: 10%;
                }
            }
        }
    }
    .body{
        flex: 1 1 auto;
        overflow-y: scroll;
        max-height: 100%;
        padding: 0 30px;
        &::-webkit-scrollbar{
            width: 0px;
        }
        .item-content{
            color: #272727;
            display: inline-block;
            position: relative;
            width: 100%;
            .item{
                border-top: 1px solid #AFAFAF;
                display: flex;
                padding: 20px 0;
                position: relative;
                width: 100%;
                &:first-child{
                    border-top: none;
                }
                .content{
                    width: 20%;
                    &.product{
                        width: 50%;
                        .product-info{
                            display: flex;
                            .product-image-wrapper{
                                display: inline-block;
                                .image-thumbnail{
                                    position: relative;
                                    width: 162px;
                                    height: 162px;
                                    overflow: hidden;
                                }
                                img{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    height: 100%;
                                    width: auto;
                                    -webkit-transform: translate(-50%,-50%);
                                        -ms-transform: translate(-50%,-50%);
                                            transform: translate(-50%,-50%);
                                    &.portrait{
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                            .product-note{
                                padding: 0 10px;
                                width: 100%;
                                span{
                                    clear: both;
                                    display: inline-block;
                                    width: 100%;
                                    &.title{
                                        font-weight: 600;
                                        font-size: 1rem;
                                    }
                                    &.attr{
                                        color: #808080;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                    .qty-control{
                        text-align: center;
                        width: 100%;
                        span{
                            font-size: 1rem;
                        }
                    }
                    .price-tag{
                        text-align: center;
                        width: 100%;
                        span{
                            font-size: 1rem;
                        }
                    }
                }
                button.delete{
                    float: right;
                    margin-right: 20px;
                    font-size: 22px;
                    span{
                        display: none;
                    }
                }
                .delete-confirmation-block{
                    background-color:rgba(255, 255, 255, 0.9);
                    clear: both;
                    height: 100%;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    span{
                        font-size: 22px;
                    }
                    .wrap-confirm-buttons{
                        padding-top: 10px;
                        button{
                            border-right: 1px solid #000;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 0 20px;
                            &.remove{
                                color: #ee3030;
                            }
                            &:last-child{
                                border-right: none;
                            }
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .personalized-info{
            border: 1px solid #ebe8e8;
            border-radius: 5px;
            display: block;
            padding: 10px 20px;
            margin-bottom: 15px;
            position: relative;
            width: 100%;
            .title{
                display: inline-flex;
                font-weight: 600;
                font-size: 1rem;
                justify-content: space-between;
                padding-bottom: 10px;
                width: 100%;
            }
            .personalize-list{
                display: block;
                width: 100%;
                .item-p{
                    display: inline-block;
                    width: 100%;
                    span{
                        display: inline-block;
                        width: 33%;
                    }
                }
            }
        }
    }
    .summary{
        display: inline-block;
        padding: 30px;
        width: 100%;
        .subtotal-information{
            border-top: 1px solid #AFAFAF;
            padding: 20px 0;
            .row-info{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                span.label{
                    color: #767676;
                    text-transform: uppercase;
                }
                span.value{
                    &.discount{
                        color: #005F85;
                    }
                }
                button.voucher{
                    text-transform: uppercase;
                    font-weight: 550;
                }
            }
        }
        .cart-action{
            border-top: 1px solid #AFAFAF;
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            button.cart{
                padding: 15px;
                background-color: #000;
                border: 1px solid #000;
                border-radius: 3px;
                color: #fff;
                font-weight: bold;
                width: calc(50%);
                text-transform: uppercase;
                &.continue-shopping{
                    background-color: #fff;
                    margin-right: 20px;
                    color: #000;
                }
                &:disabled{
                    border: 1px solid #959595;
                    background: #959595;
                    color: #eaeaea;
                }
            }
        }
    }
}

button.close-cart{
    clear: both;
    background-color: #fff;
    border: 2px solid #000;
    font-size: 25px;
    height: 60px;
    width: 60px;
    top: calc(50% - 60px);
    left: -30px;
    position: absolute;
}
button.close-cart-mobile{
    display: none;
}

@media only screen and (max-width: 1280px) {
    .slide-cart{
        width: 100% !important;
        .slide-pane__content{
            border-left: none;
            button.close-cart-mobile{
                border: 1px solid #000;
                border-radius: 50%;
                display: inline-block;
                font-weight: normal;
                font-size: 30px;
                margin-right: 15px;
                height: 38px;
                position: relative;
                top: -5px;
                width: 38px;
            }
            button.close-cart{
                display: none;
            }
            .content-wrapper{
                .head{
                    .cart-header{
                        display: none;
                    }
                }
                .body{
                    .item-content{
                        padding-right: 15px;
                        .item{
                            border-top: 1px solid #AFAFAF;
                            display: inline-block;
                            padding: 20px 0;
                            position: relative;
                            width: 100%;
                            &:first-child{
                                border-top: none;
                            }
                            .product-info{
                                display: flex;
                                .product-image-wrapper{
                                    display: flex;
                                    img{
                                        display: block;
                                        width: 162px;
                                        height: 162px;
                                    }
                                }
                                .product-note{
                                    padding: 0 10px;
                                    width: 100%;
                                    span{
                                        clear: both;
                                        display: inline-block;
                                        width: 100%;
                                        &.title{
                                            font-weight: 600;
                                            font-size: 1rem;
                                        }
                                        &.attr{
                                            color: #808080;
                                            font-size: 1rem;
                                        }
                                    }
                                    .product-action-link{
                                        clear: both;
                                        display: inline-block;
                                        margin-top: 5px;
                                        a, button{
                                            border: none;
                                            color: #005F85;
                                            font-size: 14px;
                                            margin-left: 0;
                                            padding-left: 0;
                                            &:first-child{
                                                border-left: none;
                                                margin-left: 0;
                                                padding-right: 10px;
                                                padding-left: 0;
                                            }
                                            &:hover{
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }    
                            }
                            .content{
                                clear: both;
                                display: inline-block;
                                width: 43%;
                                &.product{
                                    width: 100%;
                                    .product-info{
                                        display: flex;
                                        justify-content: space-between;
                                        width: 100%;
                                        .product-image-wrapper{
                                            .img{
                                                height: 120px;
                                                width: 120px;
                                            }
                                        }
                                        .product-note{
                                            width: 50%;
                                        }
                                    }
                                }
                                &.control-area{
                                    border-top: 1px solid #eaeaea;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                    border-left: 1px solid #eaeaea;
                                    border-bottom: 1px solid #eaeaea;
                                    height: 50px;
                                    float: left;
                                    margin-top: 20px;
                                    .qty-control{
                                        margin: 8px 0 0 10px;
                                    }
                                    &.price-tag{
                                        border-left: none;
                                        border-right: 1px solid #eaeaea;
                                        border-top-right-radius: 5px;
                                        border-bottom-right-radius: 5px;
                                        clear: none;
                                        float: left;
                                        text-align: center;
                                        span{
                                            display: block;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                            .btn-delete-wrapper{
                                clear: none;
                                float: right;
                                margin-top: 7px;
                                width: 10%;
                                button.delete{
                                    color: #ee3030;
                                    font-size: 20px;
                                    font-weight: normal;
                                    margin: 20px 0 0 0;
                                    width: 100%;
                                }
                            }
                            button.delete{
                                float: right;
                                margin-right: 20px;
                                font-size: 22px;
                                span{
                                    display: none;
                                }
                            }
                            .delete-confirmation-block{
                                background-color:rgba(255, 255, 255, 0.9);
                                clear: both;
                                height: 100%;
                                text-align: center;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                span{
                                    display: block;
                                    font-size: 18px;
                                    margin-top: 50px;
                                }
                                .wrap-confirm-buttons{
                                    padding-top: 10px;
                                    button{
                                        border-right: 1px solid #000;
                                        font-size: 16px;
                                        font-weight: 600;
                                        padding: 0 20px;
                                        &.remove{
                                            color: #ee3030;
                                        }
                                        &:last-child{
                                            border-right: none;
                                        }
                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .summary{
                    padding: 5px 30px 10px 30px;
                    .subtotal-information {
                        padding: 3px 0;
                        font-size: 12px;
                        .row-info{
                            margin-bottom: 0;
                        }
                    }
                    .cart-action{
                        border-top: 1px solid #AFAFAF;
                        display: inline-block;
                        padding-top: 10px;
                        button.cart{
                            padding: 5px 10px;
                            background-color: #000;
                            border: 1px solid #000;
                            border-radius: 3px;
                            color: #fff;
                            font-weight: bold;
                            font-size: 14px;
                            margin: 0 0 0 0;
                            text-transform: uppercase;
                            width: 100%;
                            &.continue-shopping{
                                display: none;
                            }
                            &:disabled{
                                border: 1px solid #959595;
                                background: #959595;
                                color: #eaeaea;
                            }
                        }
                    }
                }
            }
        }
    }
}

.delete-blocker{
    background-color: rgba(255, 255, 255, 0.9);
    clear: both;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

@media only screen and (max-width: 375px) {
    .head-personalize{
        margin-bottom: 10px;
        .action-personalize{
            &:first-child{
                margin-left: 0 !important;
                padding-right: 10px !important;
            }
        }
    }
    .personalized-info{
        .title{
            display: inline-block !important;
            width: 100%;
            span{
                display: inline-block;
                width: 100%;
            }
        }
        .personalize-list{
            .item-p{
                .p-info{
                    width: 50% !important;
                }
                .p-value{
                    width: 20% !important;
                }
                .p-price{
                    width: 30% !important;
                }
            }
        }
    }
}


// new cart items
.cart-item{
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    &:last-child{
        border-bottom: none;
    }
    .image-wrapper{
        display: inline-block;
        text-align: center;
        width: 30%;
        img{
            max-width: 162px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .item-detail{
        display: inline-flex;
        flex-direction: column;
        padding: 50px 15px 0 15px;
        width: 70%;
        span{
            line-height: 18px;
        }
        span.title{
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 15px;
            width: 100%;
        }
        span.attr{
            display: inline-block;
            font-size: 14px;
            width: 100%;
        }
        .personalize-wrapper{
            color: #737373;
            line-height: 18px;
            margin-top: 10px;
            .head-personalize{
                color: #272727;
                font-size: 16px;
                .action-personalize{
                    color: #272727;
                    font-size: 14px;
                    &:first-child{
                        margin-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            span.personalize-info{
                display: inline-block;
                font-size: 14px;
                line-height: 5px;
                width: 100%;
            }
        }
        button.personalize-it{
            color: #005f85;
            display: inline-block;
            font-size: 16px;
            margin-top: 10px;
            text-align: left;
            width: 105px;
        }
        span.price{
            display: inline-block;
            font-size: 20px;
            margin-top: 20px;
            width: 100%;
        }
        .item-action{
            display: inline-block;
            margin-top: 10px;
            width: 100%;
            button.delete{
                color: #ee3030;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .cart-item{
        .image-wrapper{
            width: 50%;
            img{
                max-width: auto;
                width: 100%;
            }
        }
        .item-detail{
            padding: 10px 5px 0 5px;
            width: 50%;
            span.title{
                font-size: 16px;
            }
            span.attr{
                font-size: 14px;
            }
            span.personalize{
                font-size: 14px;
            }
            span.price{
                font-size: 16px;
            }
        }
    }
}