.help-main-banner{
    position: relative;
    img{
        width: 100%;
    }
    .banner-title{
        clear: both;
        width: 100%;
        position: absolute;
        text-align: center;
        left: 0;
        top: calc(50% - 90px);
        padding: 30px;
        h2{
            color: #fff;
            font-family: Moret;
            font-size: 70px;
        }
        p{
            font-size: 20px;
        }
    }
}

.faq-search-area{
    text-align: center;
    padding: 50px 0;
    h5{
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
    }
    h3{
        font-family: Moret;
        font-size: 5rem;
        font-weight: 600;
        text-transform: capitalize;
    }
    .form-group{
        background-color: #fff;
        border: 2px solid #000;
        display: inline-block;
        margin-top: 30px;
        padding: 5px 20px;
        width: 100%;
        svg{
            color: #C1C1C1;
            float: left;
            margin-top: 12px;
            height: 30px;
            width: 30px;
        }
        input[type=text]{
            border: none;
            float: left;
            font-size: 18px;
            margin: 12px 0 0 20px;
            height: 30px;
            width: calc(100% - 120px);
            outline: none;
        }
        .btn-submit{
            float: right;
        }
    }
}

.faq-wrapper{
    padding: 30px 0;
    .wrap-question{
        padding: 20px;
        h3{
            font-family: Moret;
            font-size: 3rem;
            font-weight: 600;
            margin: 30px 0;
        }
        .main-accordion-base{
            border: none;
            box-shadow: none;
        }
        .main-accordion-summary{
            padding: 0;
            min-height: 80px;
            h5{
                font-size: 1.3rem;
                font-weight: 600;
            }
        }
        .main-accordion-details{
            padding: 0;
            p{
                font-size: 1rem;
                font-weight: 500;
            }
        }
        .main-accordion-expanded{
            .main-accordion-details{
                border-bottom: 1px solid #eaeaea;
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .help-main-banner{
        position: relative;
        img{
            width: 100%;
        }
        .banner-title{
            clear: both;
            width: 100%;
            position: absolute;
            text-align: center;
            left: 0;
            top: calc(50% - 70px);
            padding: 30px;
            h2{
                color: #fff;
                font-family: Moret;
                font-size: 30px;
            }
            p{
                font-size: 20px;
            }
        }
    }
    .faq-search-area{
        h3{
            font-size: 3rem;
        }
    }

    .faq-wrapper{
        .wrap-question{
            h3{
                font-size: 1.5rem;
            }
            .main-accordion-summary{
                h5{
                    font-size: 1rem;
                }
            }
            .main-accordion-details{
                p{
                    font-size: 1rem;
                }
            }
        }
    }

    .info-area{
        text-align: center;
        padding: 30px 0 70px 0;
        h3{
            font-size: 3rem;
        }
        a{
            font-size: 1rem;
        }
    }
}