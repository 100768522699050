@import './Slide.scss';
@import './Account/Base.scss';
@import './Status//Base.scss';
@import './Cart.scss';
@import './Dialog.scss';
@import './Auth.scss';
@import './Home.scss';
@import './About.scss';
@import './Help.scss';
@import './Catalog.scss';
@import './Product.scss';
@import './Personalize.scss';
@import './Form.scss';
@import './Table.scss';
@import './Checkout.scss';

body {
    margin: 0;
	font-family: RealistWide;
	color: #272727;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #FFFCF9;
}

a{
	color: inherit;
	text-decoration: none;
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}

ul{
	padding: 0;
	list-style-type: none;
}

button,
.button{
	background: none;
	border: none;
	cursor: pointer;
	display: inline-block;
	padding: 0;
	outline: none;
	text-align: center;
	&:focus{
		outline: none;
	}
	&.action{
		background: #fff;
		border: 2px solid #000;
		color: #000;
		font-size: 18px;
		font-weight: 600;
		padding: 20px;
		text-transform: uppercase;
		outline: none;
		width: 100%;
		&.invert{
			background-color: #000;
			color: #fff;
		}
		&:disabled{
			border: 2px solid #959595;
			background: #959595;
			color: #eaeaea;
		}
	}
}

a.link-action{
	background: #fff;
	border: 2px solid #000;
	color: #000;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
	padding: 20px;
	text-transform: uppercase;
	outline: none;
	width: 100%;
}

.mt-10{
	margin-top: 10px;
}

.mt-20{
	margin-top: 20px;
}

.mt-30{
	margin-top: 30px;
}

.button-link{
	color: #005F85;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	&:hover{
		color: #005F85;
	}
}

.ps__rail-y{
	z-index: 3;
}

.bold{
	font-weight: bold;
}

.wrapper{
	height: 100vh;
	&.slide-cart-open{
		overflow: hidden;
	}
}

.row{
	margin-left: 0;
	margin-right: 0;
}

.hide-content{
	display: none;
}

.navbar{
	background-color: #FFFFFF;
	display: flex;    
	height: 85px;
	padding: 0 50px;
	position: fixed;
	width: 100%;
	z-index: 1;
	&.has-shadow{
		box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
	}
	.toggle-mobile-menu{
		display: none;
	}
	.brand-area{
		display: flex;
		flex: 1;
		min-width: -webkit-min-content;
		a{
			width: 170px;
			img{
				width: 100%;
			}
		}
	}
	#master-menu{
		list-style-type: none;
		display: flex;
		font-size: 24px;
		font-weight: 500;
		margin: 0;
		padding: 15px 0 0 0;
		li{
			cursor: pointer;
			margin-right: 50px;
			span.dropdown{
				padding-left: 10px;
				font-size: 12px;
			}
			&:last-child{
				margin-right: 0;
			}
			&.has-hover{
				display: inline-block;
				position: relative;
				.shop-hover{
					padding-top: 20px;
					ul{
						clear: both;
						display: none;
						left: 0;
						padding-left: 0;
						position: absolute;
						list-style-type: none;
						z-index: 1;
						li{
							background-color: #fff;
							a{
								display: block;
								padding: 7px 10px;
							}
						}
						&.blog-list{
							border: 1px solid #e0e0e0;
							width: auto;
							background-color: #fff;
							padding: 10px;
						}
						&.explore-list{
							width: auto;
							li{
								clear: both;
								float: left;
								border-left: 1px solid #e0e0e0;
								border-right: 1px solid #e0e0e0;
								margin: 0;
								width: 230px;
								text-align: center;
								-webkit-transition: background-color 500ms ease-in-out;
								-ms-transition: background-color 500ms ease-in-out;
								transition: background-color 500ms ease-in-out;
								a{
									color: inherit;
									&:hover{
										text-decoration: none;
									}
								}
								&:hover{
									-webkit-transition: background-color 500ms ease-in-out;
									-ms-transition: background-color 500ms ease-in-out;
									transition: background-color 500ms ease-in-out;
									a{
										font-weight: 600;	
									}
								}
								&:first-child,
								&.separator-top{
									border-top: 1px solid #e0e0e0;
								}
								&:last-child{
									border-bottom: 1px solid #e0e0e0;
								}
							}
						}
					}
				}
				&:hover{
					.shop-hover ul{
						display: block;
						&.blog-list{
							display: inline-block;
							width: 690px;
							li{
								float: left;
								margin: 0;
								width: 220px;
								text-align: left;
								a{
									color: inherit;
									display: inline-block;
									width: 100%;
									.image-blog{
										display: inline-block;
										height: 113px;
										width: 200px;
										img{
											width: 100%;
										}
									}
									.title{
										clear: both;
										display: block;
										font-size: 16px;
										line-height: 24px;
										margin-top: 20px;
									}
									&:hover{
										.title{
											text-decoration: underline;
										}
									}
								}
								&.view-more{
									clear: both;
									font-size: 16px;
									padding: 0 10px;
									width: 100%;
									a{
										border-top: 2px solid #e0e0e0;
										display: block;
										padding: 10px 0 0 0;
										width: 100%;
										&:hover{
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.quicknav-area{
		display: flex;
		flex: 1;
		min-width: -webkit-min-content;
		justify-content: flex-end;
		position: relative;
		button{
			background: none;
			border: none;
			outline: none;
			margin-right: 25px;
			&.cart-icon{
				margin-right: 25px;
			}
			&:last-child{
				margin-right: 0;
			}
			&:hover{
				opacity: 0.8;
			}
		}
		a.user-profile{
			position: relative;
		}
	}
}


ul.quick-link-user{
	background: #fff;
	border: 1px solid #eaeaea;
	border-radius: 5px;
	box-shadow: 0 2px 2px -2px rgba(0,0,0,.7);
	clear: both;
	position: absolute;
	right: 20px;
	top: 50px;
	width: 200px;
	li{
		cursor: pointer;
		display: inline-block;
		padding: 5px 0;
		width: 100%;
		a{
			display: inline-block;
			padding: 10px 15px;
			width: 100%;
		}
		&:hover{
			opacity: 0.8;
		}
		button.btn-logout{
			color: #e94f4f;
			font-weight: bold;
			padding: 10px 15px;
		}
	}
	&.hide{
		display: none;
	}
}

#content{
	padding-top: 85px;
	&.content-without-navbar{
		padding-top: 0;
	}
}
.container{
	padding-top: 20px;
}

.sidebar-mobile-menu-overlay{
	z-index: 10;
	.slide-pane__content{
		padding: 0;
		ul.mobile-menu{
			list-style-type: none;
			padding: 0 0 10px 0;
			li{
				display: block;
				margin: 0;
				width: 100%;
				.label{
					display: flex;
					font-weight: 600;
					padding:15px 25px;
					width: 100%;
					justify-content: space-between;
				}
				button.close-menu{
					background: none;
					border: none;
					margin: 0;
					padding: 20px 22px;
					outline: none;
					img{
						width: 24px;
					}
				}
				ul.submenu{
					padding-left: 20px;
					li a {
						font-weight: normal;
						text-decoration: none;
					}
				}
				a{
					color: inherit;
					display: block;
					font-weight: 600;
					padding: 10px 25px;
					img{
						margin-top: -3px;
						width: 18px;
					}
					span{
						line-height: 22px;
						margin-left: 22px;
					}
					&.active{
						background-color: #FAF0E7;
					}
				}

				button.btn-logout{
					color: #e94f4f;
					display: block;
					font-weight: 600;
					padding: 10px 25px;
				}
			}
		}
	}
}

#footer{
	position: relative;
	width: 100%;
	background-color: #000;
	color: #fff;
	text-align: center;
	.container{
		padding: 20px 0;
		max-width: 890px;
		h1{
			font-family: Moret;
			font-size: 48px;
			margin-top: 120px;
		}
		form.subscribe{
			padding-top: 20px;
			position: relative;
			width: inherit;
			.wrap-input{
				background-color: #fff;
				display: flex;
				padding: 10px 24px;
				justify-content: space-between;
				input{
					border: none;
					float: left;
					font-size: 24px;
					padding: 0;
					outline: none;
					width: calc(100% - 70px);
				}
			}
			button{
				background: none;
				border: none;
				top: 22px;
			}
		}
		ul.footer-link{
			display: block;
			font-size: 24px;
			margin-top: 120px;
			li{
				display: inline-block;
				padding: 0 25px;
				a{
					cursor: pointer;
					display: block;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
		ul.contact-link{
			display: block;
			font-size: 24px;
			margin-top: 40px;
			li{
				display: inline-block;
				padding: 0 40px 0 0;
				span.label{
					padding-left: 15px;
					text-decoration: underline;
				}
				&.social{
					padding: 0 20px 0 0;
				}
				&:last-child{
					padding: 0;
				}
			}
		}
		.cr{
			margin-top: 120px;
			padding-bottom: 20px;
			span{
				color: #959595;
				margin-left: 20px;
			}
		}
	}
}



.info-area{
    text-align: center;
    padding: 70px 0; 
    h5{
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
    }
    h3{
        font-family: Moret;
        font-size: 5rem;
        font-weight: 600;
        margin-bottom: 50px;
    }
    a{
        background-color: #000;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.5rem;
        padding: 20px 50px;
        width: 200px;
	}
	p{
		font-size: 20px;
	}
}

/* toastify */
.Toastify{
    .global-toastify{
        background: #329132;
        border-radius: 10px;
        color: #fff;
        min-height: auto;
        padding: 10px 20px;
        &.warning{
            background: #e94f4f;
        }
    }
}

@media only screen and (max-width: 990px) {
	.navbar{
		display: block;
		padding: 10px 0 0 0;
		position: fixed;
		height: 70px;
		width: 100%;
		.toggle-mobile-menu{
			background: none;
			border: none;
			display: inline-block;
			margin-top: 10px;
			padding-left: 20px;
			position: relative;
			outline: none;
			z-index: 1;
		}
		.brand-area{
			clear: both;
			display: block;
			position: absolute;
			flex: none;
			text-align: center;
			width: 100%;
			min-width: auto;
			top: 15px;
			z-index: 0;
			a{
				display: block;
				margin: 0 auto;
				width: 120px;
				img{
					width: 100%;
				}
			}
		}
		#master-menu{
			display: none;
		}
		.quicknav-area {
			float: right;
			flex: none;
			min-width: auto;
			padding-right: 15px;
			position: relative;
			z-index: 1;
			button{
				&.cart-icon{
					margin-right: 0;
				}
			}
			a.user-profile,
			button.user-profile{
				display: none;
			}
		}
	}
	#content{
		padding-top: 70px;
		min-height: 375px;
	}
	#footer{
		position: relative;
		.container{
			padding: 20px;
			max-width: 100%;
			h1{
				font-family: Moret;
				font-size: 24px;
				margin: 0;
			}
			form.subscribe{
				padding-top: 40px;
				position: relative;
				width: inherit;
				.wrap-input{
					padding: 0 20px;
					input{
						font-size: 14px;
					}
					button{
						background: none;
						border: none;
						.icon-svg{
							width: 32px;
						}
					}
				}
			}
			ul.footer-link{
				font-size: 14px;
				margin-top: 40px;
				li{
					padding: 0 12px;
					a{
						cursor: pointer;
						display: block;
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
			ul.contact-link{
				font-size: 14px;
				margin-top: 40px;
				li{
					clear: both;
					display: block;
					padding: 10px 0;
					text-align: center;
					width: 100%;
					img{
						width: 20px;
					}
					span.label{
						padding-left: 15px;
						text-decoration: underline;
					}
					&.social{
						display: inline-block;
						width: auto;
						margin-top: 20px;
						padding: 0 20px 0 0;
						img{
							width: 32px;
						}
					}
					&:last-child{
						padding: 0;
					}
				}
			}
			.cr{
				margin-top: 20px;
				span{
					clear: both;
					display: block;
					margin: 20px 0 0 0;
				}
			}
		}
	}
}

.pagination-wrapper{
	display: inline-flex;
	justify-content: flex-end;
	padding-top: 20px;
	width: 100%;
	button{
		border-radius: 50%;
		display: inline-block;
		height: 30px;
		padding: 0;
		text-align: center;
		width: 30px;
		&:last-child{
			margin-right: 0;
		}
		&:disabled{
			&:hover{
				background: none;
			}
		}
	}
}


@media only screen and (max-width: 1440px) {
	li.has-hover{
		&:hover{
			.shop-hover ul.blog-list{
				width: 245px !important;
			}
		}
	}
}