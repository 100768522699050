.personalize-area{
    padding: 30px 0;
    h3{
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
    }
    h1{
        font-family: Moret;
        font-size: 50px;
        font-weight: 600;
    }
    span.message{
        font-size: 18px;
    }
    .input-area{
        border-bottom: 1px solid #AFAFAF;
        padding: 30px 0;
        display: inline-block;
        position: relative;
        width: 100%;
        span.letter-info{
            color: #767676;
            float: left;
            font-size: 14px;
            text-transform: uppercase;
        }
        input[type=text]{
            border: 1px solid #C1C1C1;
            clear: both;
            float: left;
            margin-top: 10px;
            padding: 20px 30px;
            text-transform: uppercase;
            width: 100px;
        }
        span.text-guide{
            clear: both;
            font-size: 14px;
            position: absolute;
            bottom: 43px;
            left: 115px;
            width: 155px;
        }
    }
    .option-switch{
        border-bottom: 1px solid #AFAFAF;
        padding: 20px 0 30px 0;
        p{
            color: #767676;
            float: left;
            font-size: 14px;
            text-transform: uppercase;
            strong{
                color: #000;
            }
            width: 100%;
        }
        .wrap-button{
            display: inline-block;
            width: 100%;
            button{
                border: 3px solid #c1c1c1;
                height: 170px;
                margin-left: 15px;
                text-align: center;
                width: 170px;
                &:first-child{
                    margin-left: 0;
                }
                div{
                    display: inline-block;
                    text-align: center;
                    position: relative;
                    img{
                        display: block;
                    }
                    span{
                        bottom: -35px;
                        color: #c1c1c1;
                        left: 0;
                        clear: both;
                        font-weight: 600;
                        font-size: 20px;
                        position: absolute;
                        text-align: left;
                        text-transform: capitalize;
                        width: 50px;
                    }
                }
                &.selected{  
                    border: 3px solid #272727;
                    div{
                        span{
                            color: #272727;
                        }
                    }
                }
            }
        }
        .color-buttons{
            display: inline-block;
            width: 100%;
            button{
                border: 2px solid #C1C1C1;
                margin-left: 15px;
                padding: 10px 20px;
                width: 150px;
                &:first-child{
                    margin-left: 0;
                }
                &:hover,&.selected{
                    border: 2px solid #000;
                }

                &.gold{
                    span{
                        color: #DBB75A;
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

                &.silver{
                    span{
                        color: #BEBEBE;
                        font-size: 22px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.personalize-product{
    .personalize-image{
        position: relative;
        margin: 0 auto;
        &.tag{
            max-width: 540px;
            max-height: 540px;
        }
        &.suitcase{
            max-width: 540px;
            max-height: 615px;
        }
        img{
            width: 100%;
        }
        span.text-letter{
            clear: both;
            color: #fff;
            font-size: 30px;
            font-weight: 600;
            position: absolute;
            text-transform: uppercase;
            bottom: 33%;
            left: 20%;
            width: 60%;
        }
        span.text-letter-tag{
            clear: both;
            position: absolute;
            color: #000;
            font-size: 3rem;
            font-weight: bold;
            left: calc(50% - 18px);
            line-height: 45px;
            top: calc(50% + 10px);
            word-wrap: break-word;
            width: 30px;
            &.gold{
                color: #DBB75A;
            }
            &.silver{
                color: #BEBEBE;
            }
        }
    }
}
.step-personalize-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    button{
        &.next{
            width: calc(50% - 10px);
        }
        &.skip{
            background-color: #eaeaea;
            border: 1px solid #c1c1c1;
            color: #575656;
            width: calc(50% - 10px);
        }
    }
}

@media only screen and (max-width: 990px) {
    .personalize-area .position-switch .wrap-button button{
        height: 90px;
        margin: 0;
        width: 90px;
        span{
            display: none;
        }
    }
}

@media only screen and (max-width: 480px) {
    .personalize-product .personalize-image span.text-letter{
        font-size: 16px;
    }

    .personalize-area .option-switch{
        .wrap-button button{
            height: 80px;
            width: 80px;
            span{
                display: none;
            }
        }
        .color-buttons button{
            width: 100px;
            span{
                font-size: 16px !important;
            }
        }
    }

    .personalize-product .personalize-image span.text-letter-tag{
        font-size: 1.5rem;
        line-height: 1.5rem;
        left: calc(50% - 0.6rem);
    }
}