.wrapper-white{
    background-color: #fff;
}
.product-nav-quick{
    padding: 150px 15px 30px 15px;
    text-align: center;
    .title{
        h5{
            font-size: 1.2em;
            font-weight: 550;
            text-transform: uppercase;
        }
        h2{
            font-size: 5em;
            font-family: Moret;
            font-weight: 600;
        }
    }
}

.image-links{
    padding-top: 35px;
    .col-link {
        max-width: 492px;
        padding: 0 10px;
        a {
            border: 1px solid #000;
            display: inline-block;
            margin-top: 20px;
            overflow: hidden;
            width: 100%;
            img{
                width: 100%;
                transition: all .25s ease-in-out;
                -webkit-transition: all .25s ease-in-out;  
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
            }
            &:hover img{
                transform: scale(1.1);
                -webkit-filter: grayscale(50%);
                filter: grayscale(50%);
            }
            span{
                color: #fff;
                font-family: Moret;
                font-size: 45px;
                position: absolute;
                text-transform: capitalize;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
.section{
    display: block;
}
.products-note{
    padding-top: 120px;
    padding-bottom: 30px;
    .container-intro{
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
        max-width: 1920px;
        .card-info{
            padding: 90px 50px;
            text-align: left;
            .head{
                .subtitle{
                    font-size: 20px;
                    text-transform: uppercase;
                }
                h1{
                    font-family: Moret;
                    font-size: 65px;
                    font-weight: 600;
                }
                h3{
                    margin-top: 20px;
                    font-family: Moret;
                    font-size: 3em;
                    font-weight: 600;
                }
            }
            .body{
                p{
                    font-size: 18px;
                    &.notes{
                        margin-top: 30px;
                    }
                    &.showcase{
                        margin-top: 30px;
                        font-size: 20px;
                    }
                }
                a.button-showcase{
                    background-color: #000000;
                    color: #fff;
                    display: inline-block;
                    font-weight: 600;
                    font-size: 22px;
                    padding: 20px 35px;
                    margin-top: 30px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 360px;
                }
                span.author{
                    font-family: Moret;
                    font-weight: 600;
                    font-size: 20px;
                }
            }
        }
        img{
            width: 100%;
            .intro-image{
                border-top: 3px solid #000;
                border-bottom: 3px solid #000;
            }
        }
    }
}

.middle-row{
    .benefit-wrapper{
        max-width: 380px;
        width: 165px;
        text-align: center;
        .wrap-icon-benefit{
            height: 165px;
            margin: 0 auto;
            position: relative;
            width: 165px;
            text-align: center;
            .helper{
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
        }
        h5{
            margin-top: 55px;
            font-family: Moret;
            font-size: 42px;
        }
        span.desc{
            display: inline-block;
            margin-top: 20px;
            font-size: 18px;
            padding: 0 70px;
        }
    }
}

@media only screen and (max-width: 990px) {
    .product-nav-quick{
        padding-top: 30px;
        .title{
            h2{
                font-size: 3em;
            }
        }
    }
    .image-links{
        padding-top: 20px;
        .col-link {
            max-width: 100%;
            a span{
                font-size: 2em;
            }
        }
    }
    .products-note{
        padding-top: 20px;
        padding-bottom: 20px;
        .container-intro{
            margin: 0 auto;
            text-align: center;
            overflow: hidden;
            .card-info{
                padding: 10px;
                text-align: left;
                .head{
                    .subtitle{
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                    h1{
                        font-size: 40px;
                    }
                    h3{
                        margin-top: 10px;
                        font-size: 32px;
                    }
                }
                .body{
                    a.button-showcase{
                        margin-bottom: 30px;
                        width: 100%;
                    }
                }
            }
            img.intro-image{
                border-top: 3px solid #000;
                border-bottom: 3px solid #000;
                width: 100%;
            }
        }
    }

    .middle-row{
        .benefit-wrapper{
            max-width: auto;
            width: auto;
            text-align: center;
            padding-bottom: 30px;
            h5{
                margin-top: 10px;
                font-family: Moret;
                font-size: 42px;
            }
            span.desc{
                display: inline-block;
                margin-top: 20px;
                font-size: 18px;
                padding: 0 70px;
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .image-links{
        .col-link a span{
            font-size: 2em;
        }
    }
}