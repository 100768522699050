.auth-content{
    margin: 0 auto;
    padding: 50px;
    text-align: center;
    max-width: 500px;
    &.register,
    &.warn-message{
        max-width: 620px;
    }
    &.warn-message{
        h3{
            color: #c73535;
        }
    }
    h3{
        display: block;
        font-family: Moret;
        font-size: 48px;
        font-weight: 550;
        margin-bottom: 70px;
    }
    button, a.button-link{
        color: #000;
        display: block;
        font-weight: 550;
        font-size: 1em;
        margin-bottom: 35px;
        padding: 20px;
        text-transform: uppercase;
        width: 100%;
        height: 72px;
        &.email{
            background-color: #000;
            border: 1px solid #000;
            color: #fff;
        }
        &.google{
            background-color: #fff;
            border: 1px solid #AFAFAF;
        }
        &.facebook{
            background-color: #4267B2;
            border: 1px solid #4267B2;
            color: #fff;
            img{
                margin-top: -5px;
            }
        }
        span{
            display: inline-block;
            margin-left: 15px;
        }
    }
    .link-outside{
        display: block;
        font-size: 20px;
        font-weight: 550;
        padding: 30px 0;
        margin-bottom: 0;
        text-transform: none;
        text-decoration: underline;
        &.has-border{
            border-top: 1px solid #AFAFAF;
        }
        span{
            text-decoration: underline;
            text-transform: none;
        }
    }
}

.checkbox-wrapper{
    display: flex;
    justify-content: space-between;

    .container-checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 2px solid #272727;
        background-color: #eee;
      }
      
      /* On mouse-over, add a grey background color */
      .container-checkbox:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .container-checkbox input:checked ~ .checkmark {
        background-color: #808080;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .container-checkbox input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container-checkbox .checkmark:after {
        left: 7px;
        top: 1px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
}

@media only screen and (max-width: 990px) {
    .auth-content{
        padding: 10px;
        button{
            font-size: 0.8em;
        }
        a.register-link{
            padding-bottom: 50px;
            width: 100%;
        }
    }
}