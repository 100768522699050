.back-button-page{
    display: inline-block;
    margin-bottom: 20px;
    text-align: center;
    span.icon{
        border: 2px solid #000;
        display: inline-block;
        height: 25px;
        margin-right: 10px;
        width: 25px;
    }
    span.text{
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

table.table-order-history{
    thead{
        background: #FFFCF9;
        tr th{
            color: #767676;
            font-weight: 550;
            padding: 10px;
            text-transform: uppercase;
        }
    }
    tbody{
        tr{
            &.can-hover:hover{
                background: rgba(0, 0, 0, 0.02);
                cursor: pointer;
            }
            td{
                padding: 10px;
                vertical-align: top;
                span.ship-name{
                    color: #272727;
                    display: inline-block;
                    font-weight: 600;
                    width: 100%;
                }
                span.order-no{
                    color: #767676;
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
}

.order-detail-container{
    .header{
        display: inline-block;
        width: 100%;
        h3{
            float: left;
            font-family: Moret;
            font-size: 2.75rem;
            font-weight: 600;
            margin-bottom: 0;
        }
        .base-status{
            float: left;
            font-size: 14px;
            margin: 10px 0 0 15px;
            position: relative;
        }
    }
    .content{
        margin-top: 35px;
        .card-detail{
            padding: 15px 0;
            width: 100%;
            &:nth-child(odd){
                border-right: 1px solid #2920181A;
                padding-right: 15px;
            }
            &:nth-child(even){
                padding-left: 15px;
            }
            .label{
                color: #767676;
                display: inline-block;
                margin-bottom: 5px;
                font-size: 14px;
                text-transform: uppercase;
                width: 100%;
            }
            .value{
                display: inline-block;
                width: 100%;
                span{
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
    .ordered-items-container{
        margin-top: 35px;
        h2{
            border-bottom: 1px solid #B7B7B7;
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 15px;
            width: 100%;
        }
        .item-list{
            border-bottom: 1px solid #B7B7B7;
            padding: 10px 0;
            .image-wrapper{
                text-align: center;
                img{
                    max-width: 162px;
                    width: 100%;
                    &.image-order-detail{
                        height: 150px;
                        width: 150px;
                    }
                }
            }
            .product-desc{
                display: flex;
                width: 100%;
                .product-info{
                    padding: 15px 0;
                    width: 75%;
                    span{
                        color: #808080;
                        display: inline-block;
                        width: 100%;
                        strong{
                            color: #000;
                            font-weight: 600;
                        }
                    }
                }
                .product-price{
                    padding: 15px 0;
                    width: 25%;
                }
            }
        }
    }
    .summary{
        border-bottom: 1px solid #B7B7B7;
        padding: 20px 0;
        .row{
            display: flex;
            font-size: 16px;
            line-height: 30px;
            justify-content: space-between;
            .label{
                color: #767676;
                text-transform: uppercase;
            }
        }
    }
    .grand-total{
        font-weight: bold;
        padding: 20px 0;
        .row{
            display: flex;
            font-size: 16px;
            line-height: 30px;
            justify-content: space-between;
            .label{
                text-transform: uppercase;
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .order-detail-container{
        .content{
            .card-detail{
                &:nth-child(odd){
                    padding-right: 0;
                }
                &:nth-child(even){
                    padding-left: 0;
                }
            }
        }

        .ordered-items-container{
            .item-list{
                .image-wrapper{
                    padding: 0;
                }
                .product-desc{
                    display: inline-block;
                    padding: 0;
                    width: 100%;
                    .product-info{
                        padding: 15px 0;
                        width: 100%;
                        span{
                            color: #808080;
                            display: inline-block;
                            width: 100%;
                            strong{
                                color: #000;
                                font-weight: 600;
                            }
                        }
                    }
                    .product-price{
                        padding: 0;
                        width: 100%;
                    }
                }
            }
        }

        .summary{
            .row{
                font-size: 14px;
                justify-content: space-between;
                .label{
                    color: #767676;
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 14px;
                }
            }
        }
        .grand-total{
            .row{
                font-size: 14px;
                justify-content: space-between;
                .label{
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 14px;
                }
            }
        }
    }
}