.product-detail{
    display: flex;
    .information-detail{
        h2.title{
            font-family: Moret;
            font-size: 2.7rem;
            margin-top: 20px;
            span{
                font-weight: 600;
                text-transform: capitalize;
            }
            .wrap-select{
                border: 2px solid #000;
                display: inline;
                margin-left: 20px;
                padding: 0 10px;
                select{
                    border: none;
                    background: none;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
        button.compare-button{
            border-bottom: 1px solid #005F85;
            color: #005F85;
            font-weight: 600;
            font-size: 16px;
            margin-top: 15px;
            &:hover{
                opacity: 0.8;
            }
        }
        p.description{
            font-size: 16px;
            margin-top: 30px;
        }
        .color-picker{
            border-bottom: 1px solid #C1C1C1;
            padding-bottom: 20px;
        }
        .more-desc{
            margin-top: 15px;
            .main-accordion-base{
                border-top: 1px solid #C1C1C1;
                padding: 10px 0;
                &:first-child{
                    border-top: none;
                }
                box-shadow: none;
                .main-accordion-summary{
                    padding: 0;
                    h5{
                        font-size: 18px;
                    }
                }
                .main-accordion-details{
                    padding: 0;
                    font-size: 16px;
                    p{
                        margin: 0;
                    }
                }
            }
        }
    }
}

.review-rate{
    span{
        padding-left: 10px;
    }
}

p.detail-info-selected{
    color: #767676;
    font-size: 14px;
    text-transform: uppercase;
    &.bundling-info{
        padding-top: 30px;
    }
    strong{
        color: #000;
        margin-left: 10px;
    }
}
.bundling-menu{
    border-top: 1px solid #C1C1C1;
    border-bottom: 1px solid #C1C1C1;
    width: 100%;
}
.bundling-opt{
    display: inline-flex;
    gap: 10px;
    width: 100%;
    .bundle-btn{
        border: 3px solid #C1C1C1;
        height: 170px;
        position: relative;
        width: 170px;
        &::after{
            content: '';
            border: 2px solid #C1C1C1;
            clear: both;
            color: #fff;
            height: 22px;
            position: absolute;
            right: 5px;
            text-align: center;
            top: 10px;
            width: 22px;
        }
        .icon-bundling{
            display: inline-block;
            margin-top: -15px;
            text-align: center;
            width: 100%;
            img{
                width: auto;
            }
            span{
                bottom: 15px;
                clear: both;
                color: #C1C1C1;
                left: 0;
                position: absolute;
                font-weight: bold;
                width: 100%;
            }
        }
        &.selected{
            border: 3px solid #272727;
            &::after{
                content: '';
                background: #000;
                border: 2px solid #000;
                clear: both;
                color: #fff;
                height: 22px;
                position: absolute;
                right: 5px;
                text-align: center;
                top: 5px;
                width: 22px;
            }
            .icon-bundling{
                span{
                    color: #000;
                }
            }
        }
        input[type=checkbox]{
            clear: both;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.separate-section{
    padding: 35px 0;
    display: inline-block;
    a.personalize-detail{
        display: inline-block;
        width: 100%;
    }
}

.action-wrap{
    padding-top: 15px;
    width: 100%;
    button{
        margin-bottom: 20px;
    }
}

.product-gallery{
    position: relative;
    height: 598px;
    width: 525px;
    .preview-big{
        background: #fff;
        width: 100%;
        img{
            width: 525px;
            height: 598px;
        }
    }
    .thumbnails{
        clear: both;
        position: absolute;
        text-align: center;
        bottom: 10px;
        width: 100%;
        li{
            display: inline;
            margin-left: 15px;
            &:first-child{
                margin-left: 0;
            }
            button{
                border: 2px solid #000;
                background: #fff;
                height: 70px;
                overflow: hidden;
                width: 70px;
                img{
                    object-fit: cover;
                    width: 100%;
                    &.selected{
                        opacity: 0.75;
                    }
                }
            }
        }
    }
}


.title-review{
    display: inline-block;
    text-align: center;
    width: 100%;
    h5{
        font-size: 20px;
        font-weight: 500;
    }
    h3{
        font-family: Moret;
        font-size: 60px;
    }
}

.user-reviews{
    display: flex;
    padding: 20px 0;
    .review-list{
        border-bottom: 1px solid #AFAFAF;
        display: block;
        padding: 30px 0;
        .user-info{
            color: #939190;
            position: relative;
            width: 100%;
            img{
                position: absolute;
            }
            span{
                font-size: 20px;
                &.username{
                    border-right: 1px solid #939190;
                    padding-left: 50px;
                    padding-right: 20px;
                    margin-right: 20px;
                }
            }
        }
        .review-rate{
            font-size: 20px;
            padding: 10px 0;
        }
        .review-text{
            clear: both;
            font-size: 18px;
            width: 100%;
        }
        .review-image{
            margin-top: 20px;
            .review-thumbnail{
                border: 2px solid #000;
                position: relative;
                width: 200px;
                height: 200px;
                overflow: hidden;
            }
            img{
                position: absolute;
                left: 50%;
                top: 50%;
                height: 100%;
                width: auto;
                -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
                &.portrait{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .load-more{
        text-align: center;
        padding-top: 30px;
        width: 100%;
        button.btn-load-review{
            max-width: 300px;
        }
    }
}

.suggestion{
    display: flex;
    padding: 20px 0;
}

.wrap-product-suggest{
    background: none;
    color: inherit;
    padding: 10px 0;
    display: inline-block;
    a{
        background: none;
        display: block;
        padding: 0;
        margin: 0;
        text-align: center;
        width: 100%;
    }
    .wrap-product-image{
        min-height: 550px;
        position: relative;
        .transition-image{
            opacity: 50%;
            transition:opacity 0.8s
        }
    }
    span{
        color: #000;
        display: block;
        padding: 5px 0;
        text-align: center;
        width: 100%;
        &.title{
            font-weight: 600;
            font-size: 1.5rem;
        }
        &.price{
            font-size: 1.2rem;
        }
    }
}

.dialog-compare{
    border-radius: 0 !important;
    border: 2px solid #000;
    min-width: 1124px;
    padding: 20px;
    .responsive-dialog-title > button{
        border: 2px solid #000;
        color: #000;
        padding: 5px;
        right: 20px;
        top: 20px;
    }
}

.compare-information{
    padding: 0;
    .wrap-product-compare{
        background: none;
        color: inherit;
        display: inline-block;
        overflow: hidden;
        padding: 0 20px;
        .compare-item{
            background: none;
            display: block;
            padding: 0;
            margin: 0;
            text-align: center;
            width: 100%;
        }
        .wrap-product-image{
            min-height: 450px;
            position: relative;
            .transition-image{
                opacity: 50%;
                transition:opacity 0.8s
            }
        }
        span{
            display: block;
            text-align: center;
            width: 100%;
            &.title{
                color: #000;
                font-family: Moret;
                font-weight: 600;
                font-size: 2rem;
                padding: 5px 0;
            }
            &.price{
                padding: 5px 0;
                font-size: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 990px) {

    .product-detail{
        overflow: hidden;
        .information-detail{
            h2.title{
                display: inline-block;
                width: 100%;
                span{
                    float: left;
                    font-size: 2rem;
                    margin-top: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: calc(100% - 90px);
                }
                .wrap-select{
                    margin: 0;
                    float: left;
                }
            }
        }
    }

    .product-gallery{
        height: auto;
        width: 100%;
        .thumbnails{
            li{
                margin-left: 10px;
                button{
                    height: 45px;
                    width: 45px;
                }
            }
        }
        .preview-big{
            img{
                height: auto;
                width: 100%;
            }
        }
    }

    .dialog-compare{
        min-width: auto;
        .compare-information{
            .wrap-product-compare{
                padding: 0;
            }
        }
    }

    .bundling-opt{
        position: relative;
        button.bundle-btn{
            display: block;
            height: 100px;
            width: 100px;
            .icon-bundling{
                display: inline-block;
                width: 100%;
                img{
                    width: 30px;
                }
                img.combined{
                    width: 70px;
                }

                span{
                    bottom: 5px;
                }
            }
            &.selected{
                border: 3px solid #272727;
                &::after{
                    content: '';
                    background: #000;
                    border: 2px solid #000;
                    clear: both;
                    color: #fff;
                    height: 10px;
                    position: absolute;
                    right: 5px;
                    text-align: center;
                    top: 5px;
                    width: 10px;
                }
                .icon-bundling{
                    span{
                        color: #000;
                    }
                }
            }
            &::after{
                content: '';
                border: 2px solid #C1C1C1;
                clear: both;
                color: #fff;
                height: 10px;
                position: absolute;
                right: 5px;
                text-align: center;
                top: 5px;
                width: 10px;
            }
        }
    }

    .title-review{
        h5{
            font-size: 20px;
        }
        h3{
            font-size: 30px;
        }
    }
}