.main-banner{
    position: relative;
    img{
        width: 100%;
    }
    .about-title{
        clear: both;
        color: #fff;
        position: absolute;
        left: 30px;
        top: calc(50% - 192px);
        padding: 80px;
        width: 640px;
        h3{
            font-family: Moret;
            font-size: 60px;
            margin-bottom: 30px;
        }
        p{
            font-size: 20px;
        }
    }
}

.about-text{
    font-size: 1.5rem;
    padding: 30px;
}

@media only screen and (max-width: 990px) {
    .main-banner{
        .about-title{
            clear: both;
            color: #fff;
            position: absolute;
            padding: 0;
            left: 20px;
            top: 20px;
            width: 90%;
            h3{
                font-family: Moret;
                font-size: 2rem;
                margin-bottom: 10px;
            }
            p{
                font-size: 0.9rem;
            }
        }
    }
    .about-text{
        font-size: 1rem;
    }
}